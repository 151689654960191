import React, { useState } from 'react'

import styles from './Funds.module.sass'
import { Icon } from '../Icon/Icon'
import { Item } from './Item/Item'
import { useIntl } from 'react-intl'

export const Funds = ({ items, children }) => {
  const [search, setSearch] = useState('')
  const intl = useIntl()

  const messages = {
    search: intl.formatMessage({
      id: 'search',
      defaultMessage: 'Search',
    }),
    type: intl.formatMessage({
      id: 'type',
      defaultMessage: 'Type',
    }),
    amount: intl.formatMessage({
      id: 'amount',
      defaultMessage: 'Amount',
    }),
    status: intl.formatMessage({
      id: 'status',
      defaultMessage: 'Status',
    }),
    info: intl.formatMessage({
      id: 'info',
      defaultMessage: 'Info',
    }),
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <form className={styles.form} action=''>
          <input
            className={styles.input}
            type='text'
            value={search}
            onChange={e => setSearch(e.target.value)}
            name='search'
            placeholder={messages.search}
            required
          />
          <button className={styles.result}>
            <Icon name='search' size='20' />
          </button>
        </form>
        {/* <button className={styles.link}>
          <span>Borrowing history</span>
          <Icon name='arrow-right' size='24' />
        </button> */}
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>{messages.type}</div>
          <div className={styles.col}>{messages.type}</div>
          <div className={styles.col}>{messages.amount}</div>
          <div className={styles.col}>{messages.status}</div>
          <div className={styles.col}>{messages.info}</div>
        </div>
        {items?.map((x, index) => (
          <Item
            className={styles.item}
            item={x}
            key={index}
            children={children}
          />
        ))}
      </div>
    </div>
  )
}
