import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Item.module.sass'
import { TransactionData } from 'entities/accounts/api/types'
import {
  transactionType,
  transactionStatusTitle,
  typeTitle,
} from 'entities/accounts/helpers/helpers'
import { priceToView } from 'shared/utils/format/currency'

export const Item = ({ item }: { item: TransactionData }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} onClick={() => setVisible(!visible)}>
          <div className={styles.col}>
            <div className={styles.currency}>
              {/* <div className={styles.icon}>
                <img src={item.icon} alt='Currency' />
              </div> */}
              <div className={styles.details}>
                <div className={styles.info}>{`${typeTitle[item.type]}`}</div>
                {/* <div className={styles.text}>{item.content}</div> */}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{`${
              transactionType[item.transaction_mode]
            }`}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {priceToView(item.amount, item.currency)}
            </div>
            {/* <div className={styles.text}>{item.priceTotal}</div> */}
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {transactionStatusTitle[item.status]}
            </div>
            {/* <div className={styles.text}>{item.priceAvailable}</div> */}
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item.info}</div>
            {/* <div className={styles.text}>{item.priceInterest}</div> */}
          </div>
        </div>
        {/* <div className={styles.btns}>{children}</div> */}
      </div>
    </>
  )
}
